import logo from './logo.svg';
import './App.css';
import Lottie from 'react-lottie';
import animationData from './anim_chicky.json';

function App() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }

  };

  return (
    <div className="App" style={{ textAlign: 'center', marginTop: '50px' }}>
      <Lottie options={defaultOptions} height={400} width={400} />
      <h1 style={{fontSize: '20px', fontWeight: 600}}>Coming Soon...</h1>
      <h1 style={{fontSize: '14px'}}>RH</h1>
    </div>
  );
}

export default App;
